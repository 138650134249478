/* Countdown Timer script */
(function ($) {
  'use strict';

  function getTimeDifference(endtime) {
    var t = endtime - new Date();
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));

    return {
      'total': t,
      'days': days,
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds
    };
  }

  function initializeClock(id, endtime) {
    var clock;

    if (jQuery.type(id) === 'string') {
      clock = document.getElementById(id);
    } else {
      clock = id;
    }
    if (typeof clock === 'undefined' || !clock) {
      return;
    }
    var daysElem = clock.querySelector('.days');
    var hoursElem = clock.querySelector('.hours');
    var minutesElem = clock.querySelector('.minutes');
    var secondsElem = clock.querySelector('.seconds');
    var t = getTimeDifference(endtime);

    function updateClock() {
      // On timer complete
      if (t.total < 1000) {
        clearInterval(timeinterval);
        onTimerComplete(clock);

        return;
      }

      // Updating time
      if (t.seconds == 0) {
        t.days = t.days == 0 ? 0 : t.hours == 0 ? t.days - 1 : t.days;
        t.hours = t.days == 0 && t.hours == 0 ? 0 : t.hours == 0 ? 23 : t.minutes == 0 ? t.hours - 1 : t.hours;
        t.minutes = t.hours == 0 && t.minutes == 0 ? 0 : t.minutes == 0 ? 59 : t.minutes - 1;
        t.seconds = 60;
      }
      t.seconds -= 1;
      t.total -= 1000;

      if (daysElem) {
        daysElem.innerHTML = t.days;
      }
      if (hoursElem) {
        hoursElem.innerHTML = t.hours;
      }
      if (minutesElem) {
        minutesElem.innerHTML = t.minutes;
      }
      if (secondsElem) {
        secondsElem.innerHTML = t.seconds;
      }
    }
    updateClock();
    var timeinterval = setInterval(updateClock, 1000);
  }

  var onTimerComplete = function (clock_obj) {
    var textComplete = '';
    // var textCompleteElement = document.getElementById("textcomplete");
    var textCompleteElement = clock_obj.querySelector('#textcomplete');

    if (typeof textCompleteElement !== 'undefined' && textCompleteElement) {
      textComplete = textCompleteElement.innerHTML;
    }
    if (textComplete.length) {
      var textc = clock_obj.querySelector('#textcomplete');
      var textc_object = $(clock_obj).find('#textcomplete');

      if (textc) {
        // Hide the clock and show the complete text
        textc.innerHTML = textComplete;
        $(clock_obj).find('#coming_soon').hide();
        $(clock_obj).find('#watch').hide();
        $(clock_obj).find('#tag_line').hide();
        textc_object.show();
      }
    }
  };

  Drupal.behaviors.ReferAFriendV1 = {
    init: function (context) {
      $('.countdown_clock_class').each(function (i, obj) {
        var fullDateElement = obj.querySelector('#fulldatevalue');

        if (typeof fullDateElement !== 'undefined' && fullDateElement) {
          var fullDate = fullDateElement.innerHTML.replace(/&nbsp;/g, '');
          var endtime = new Date(fullDate);

          initializeClock(obj, endtime);
        }
      });
    },
    attach: function (context) {
      this.init(context);
    }
  };
})(jQuery);
